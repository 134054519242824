// App.js
import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { Toaster } from 'react-hot-toast';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddCategory from './pages/AddCategory';
import ViewCategory from './pages/ViewCategory';
import EditCategory from './pages/EditCategory';
import AddPackage from './pages/AddPackage';
import ViewPackage from './pages/ViewPackage';
import ViewPackageDetails from './pages/ViewPackageDetails';
import EditPackage from './pages/EditPackage';
import ViewBookings from './pages/ViewBookings';
import BannerManager from './pages/BannerManager';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import {LoadingSpinner} from './components/LoadingSpinner';

function App() {
  return (
    <AuthProvider>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/categories">
            <Route index element={<ViewCategory />} />
            <Route path="add" element={<AddCategory />} />
            <Route path="edit/:id" element={<EditCategory />} />
          </Route>
          <Route path="/packages">
            <Route index element={<ViewPackage />} />
            <Route path="add" element={<AddPackage />} />
            <Route path="edit/:id" element={<EditPackage />} />
            <Route path="details/:id" element={<ViewPackageDetails />} />
          </Route>
          <Route path="/bookings" element={<ViewBookings />} />
          <Route path="/banners" element={<BannerManager />} />
          <Route path="*" element={<p>Not Found</p>} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

const ProtectedLayout = () => {
  const { token, loading } = useAuth();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  if (loading) return <LoadingSpinner />;
  if (!token) return <Navigate to="/" replace />;

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="w-64 bg-white border-r border-gray-200">
          <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        </div>
      </div>

      {/* Mobile sidebar */}
      {sidebarOpen && (
        <div className="lg:hidden fixed inset-0 flex z-40">
          <div 
            className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
            onClick={() => setSidebarOpen(false)}
          />
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
            <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
          </div>
        </div>
      )}

      {/* Main content */}
      <div className="flex-1 overflow-auto flex flex-col">
        <Header setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;