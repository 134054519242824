// pages/ViewPackageDetails.js
import React,{ useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { 
  FaClock, FaGift, FaImage, FaCheck, FaEdit, FaArrowLeft, 
  FaCalendar, FaMoneyBillWave, FaWhatsapp 
} from "react-icons/fa";
import { useAsync } from "../hooks/useAsync";
import { LoadingSpinner } from "../components/LoadingSpinner";
import axios from "axios";
import API from "../connection/connections";
import toast from "react-hot-toast";



const InfoSection = ({ title, children, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden">
    <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        <Icon className="h-5 w-5 mr-2 text-gray-500" />
        {title}
      </h3>
    </div>
    <div className="p-4">{children}</div>
  </div>
);

export default function ViewPackageDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [packageRes, categoriesRes] = await Promise.all([
          axios.get(`${API}/packages/viewpackagedetail/${id}`),
          axios.get(`${API}/categories/view`)
        ]);
        
        setPackageData(packageRes.data);
        setCategories(categoriesRes.data);
      } catch (err) {
        console.error(err);
        setError(err);
        toast.error("Failed to load package details");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <LoadingSpinner />;
  
  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Error loading package</h3>
          <p className="text-gray-500">{error.message}</p>
        </div>
      </div>
    );
  }

  const category = categories.find(c => c._id === packageData.categoryId._id);


  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/packages"
                className="mr-4 flex items-center text-gray-600 hover:text-gray-900"
              >
                <FaArrowLeft className="h-5 w-5" />
              </Link>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{packageData.packageName}</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Category: {category?.categoryName}
                </p>
              </div>
            </div>
            <Link
              to={`/packages/edit/${id}`}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
            >
              <FaEdit className="mr-2 h-5 w-5" />
              Edit Package
            </Link>
          </div>
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {/* Main Info Column */}
          <div className="lg:col-span-2 space-y-6">
            {/* Basic Information */}
            <InfoSection title="Package Information" icon={FaMoneyBillWave}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-sm font-medium text-gray-500">Regular Price</label>
                  <p className="mt-1 text-lg font-semibold text-gray-900">
                    AED {packageData.price.toLocaleString()}
                  </p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-500">Special Price</label>
                  <p className="mt-1 text-lg font-semibold text-gray-900">
                    AED {packageData.specialPrice.toLocaleString()}
                  </p>
                </div>
                <div className="col-span-2">
                  <label className="text-sm font-medium text-gray-500">Package USP</label>
                  <p className="mt-1 text-gray-900">{packageData.packageUSP}</p>
                </div>
              </div>
            </InfoSection>

            {/* Images Gallery */}
            <InfoSection title="Package Images" icon={FaImage}>
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
                {packageData.images.map((image, index) => (
                  <div key={index} className="relative group aspect-w-3 aspect-h-2">
                    <img
                      src={`https://tripmenia.com/public/upload/${image}`}
                      alt={`Package ${index + 1}`}
                      className="object-cover rounded-lg shadow-sm group-hover:opacity-75 transition-opacity duration-200"
                    />
                  </div>
                ))}
              </div>
            </InfoSection>

            {/* Description */}
            <InfoSection title="Description" icon={FaCalendar}>
              <div 
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: packageData.description }} 
              />
            </InfoSection>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Status */}
            <InfoSection title="Package Status" icon={FaCheck}>
              <div className={`flex items-center p-4 rounded-lg ${
                packageData.enabled ? 'bg-green-50' : 'bg-red-50'
              }`}>
                <FaCheck className={`h-5 w-5 mr-2 ${
                  packageData.enabled ? 'text-green-500' : 'text-red-500'
                }`} />
                <span className={`text-sm font-medium ${
                  packageData.enabled ? 'text-green-800' : 'text-red-800'
                }`}>
                  {packageData.enabled ? 'Enabled' : 'Disabled'}
                </span>
              </div>
              {packageData.bookingOnlyByWhatsapp && (
                <div className="mt-4 flex items-center p-4 bg-green-50 rounded-lg">
                  <FaWhatsapp className="h-5 w-5 mr-2 text-green-500" />
                  <span className="text-sm font-medium text-green-800">
                    WhatsApp Booking Only
                  </span>
                </div>
              )}
            </InfoSection>

            {/* Time Slots */}
            <InfoSection title="Time Slots" icon={FaClock}>
              <div className="space-y-3">
                {packageData.timeSlots.map((slot, index) => (
                  <div 
                    key={index}
                    className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                  >
                    <FaClock className="h-4 w-4 text-gray-400" />
                    <span className="flex-1 mx-3 text-sm text-gray-900">
                      {slot.from} - {slot.to}
                    </span>
                  </div>
                ))}
              </div>
            </InfoSection>

            {/* Perks */}
            <InfoSection title="Package Perks" icon={FaGift}>
              <div className="space-y-3">
                {packageData.perks.map((perk, index) => (
                  <div 
                    key={index}
                    className="flex items-center p-3 bg-gray-50 rounded-lg"
                  >
                    <i className={`${perk.icon} text-blue-500 mr-3`} />
                    <span className="text-sm text-gray-900">{perk.text}</span>
                  </div>
                ))}
              </div>
            </InfoSection>
          </div>
        </div>
      </div>
    </div>
  );
}