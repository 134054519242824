import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Home, Package, List, Calendar, Image, X
} from 'lucide-react';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: Home },
  { name: 'Categories', href: '/categories', icon: List },
  { name: 'Packages', href: '/packages', icon: Package },
  { name: 'Bookings', href: '/bookings', icon: Calendar },
  { name: 'Banners', href: '/banners', icon: Image },
];

const Sidebar = ({ isOpen, setIsOpen }) => {
  return (
    <div className="h-full flex flex-col">
      
      <div className="lg:hidden flex items-center justify-between h-16 px-4 border-b border-gray-200 sticky top-0 bg-white z-10">
        <h1 className="text-xl font-bold text-gray-900">Menu</h1>
        <button
          onClick={() => setIsOpen(false)}
          className="p-2 rounded-md text-gray-500 hover:text-gray-600 hover:bg-gray-100"
        >
          <X size={24} />
        </button>
      </div>

      {/* Navigation */}
      <nav className="flex-1 px-4 py-4 space-y-1 overflow-y-auto">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              `flex items-center px-4 py-2 text-sm font-medium rounded-md 
              ${
                isActive
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'text-gray-700 hover:bg-gray-50'
              }`
            }
          >
            <item.icon className="mr-3 h-5 w-5" />
            {item.name}
          </NavLink>
        ))}
      </nav>

      {/* Footer */}
      <div className="flex-shrink-0 border-t border-gray-200 p-4">
        <div className="flex flex-col items-center space-y-2">
          <span className="text-xs text-gray-500">Developed by</span>
          
           <a href="https://www.hatchconcepts.in"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center"
          >
            <img
              src="https://www.hatchconcepts.in/img/logo.png"
              alt="Hatch Concepts Logo"
              className="h-6 w-auto"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;