// pages/ViewBookings.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAsync } from "../hooks/useAsync";
import { LoadingSpinner } from "../components/LoadingSpinner";
import API from "../connection/connections";
import {
  FaSort, FaSearch,
  FaDownload, FaEye, FaWhatsapp, FaEdit
} from "react-icons/fa";
import { format } from "date-fns";
import * as XLSX from 'xlsx';
import toast from "react-hot-toast";

export default function ViewBookings() {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    paymentStatus: "",
    startDate: "",
    endDate: "",
  });
  const [sortConfig, setSortConfig] = useState({
    field: "bookedAt",
    direction: "desc"
  });
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [showStatusUpdate, setShowStatusUpdate] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [updatingBookingId, setUpdatingBookingId] = useState(null);

  // Fetch bookings with filters and sorting
  const { status, error, execute: fetchBookings } = useAsync(async (page) => {
    const queryParams = new URLSearchParams({
      page,
      sort: `${sortConfig.direction === "desc" ? "-" : ""}${sortConfig.field}`,
      ...(filters.search && { search: filters.search }),
      ...(filters.status && { status: filters.status }),
      ...(filters.paymentStatus && { paymentStatus: filters.paymentStatus }),
      ...(filters.startDate && { startDate: filters.startDate }),
      ...(filters.endDate && { endDate: filters.endDate })
    });

    const response = await axios.get(`${API}/bookings/view?${queryParams}`);
    setBookings(response.data.bookings);
    setTotalPages(response.data.pages);
    return response.data;
  });

  useEffect(() => {
    fetchBookings(currentPage);
  }, [currentPage, sortConfig, filters]);

  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === "asc" ? "desc" : "asc"
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters({
      search: "",
      status: "",
      paymentStatus: "",
      startDate: "",
      endDate: ""
    });
    setCurrentPage(1);
  };

  const exportToExcel = () => {
    try {
      const exportData = bookings.map(booking => ({
        'Booking ID': booking._id,
        'Package Name': booking.bookingPackageName,
        'Client Name': booking.clientName,
        'Client Email': booking.clientEmail,
        'Phone': `${booking.clientCountryCode}${booking.clientPhoneNo}`,
        'Amount Paid': booking.amountPaid,
        'Total Guests': booking.totalGuest,
        'Payment Status': booking.paymentStatus,
        'Status': booking.status,
        'Booking Date': format(new Date(booking.bookingDate), 'dd/MM/yyyy HH:mm'),
        'Booked At': format(new Date(booking.bookedAt), 'dd/MM/yyyy HH:mm')
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Bookings");
      XLSX.writeFile(wb, `Bookings_${format(new Date(), 'dd-MM-yyyy')}.xlsx`);
      toast.success("Bookings exported successfully");
    } catch (error) {
      toast.error("Failed to export bookings");
      console.error("Export error:", error);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      Pending: "bg-yellow-100 text-yellow-800",
      Complete: "bg-green-100 text-green-800",
      Cancel: "bg-red-100 text-red-800",
      completed: "bg-green-100 text-green-800",
      pending: "bg-yellow-100 text-yellow-800",
      cancelled: "bg-red-100 text-red-800"
    };
    return colors[status] || "bg-gray-100 text-gray-800";
  };

  const handleStatusUpdate = async (bookingId, newStatus) => {
    setIsUpdatingStatus(true);
    try {
      await axios.put(`${API}/bookings/update/${bookingId}`, {
        status: newStatus
      });

      toast.success("Booking status updated successfully");
      fetchBookings(currentPage);
      setShowStatusUpdate(false);
      setUpdatingBookingId(null);
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update booking status");
      console.error("Status update error:", error);
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Bookings</h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage and view all booking details
              </p>
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                onClick={exportToExcel}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
              >
                <FaDownload className="mr-2 h-5 w-5" />
                Export to Excel
              </button>
            </div>
          </div>
        </div>

        {/* Filters */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
            {/* Search */}
            <div className="col-span-1 md:col-span-2">
              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="search"
                  value={filters.search}
                  onChange={handleFilterChange}
                  className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search bookings..."
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaSearch className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </div>

            {/* Status Filter */}
            <div>
              <select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                <option value="">All Status</option>
                <option value="Pending">Pending</option>
                <option value="Complete">Complete</option>
                <option value="Cancel">Cancelled</option>
              </select>
            </div>

            {/* Payment Status Filter */}
            <div>
              <select
                name="paymentStatus"
                value={filters.paymentStatus}
                onChange={handleFilterChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                <option value="">All Payment Status</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>

          {/* Date Range Filters */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          {/* Active Filters */}
          {Object.values(filters).some(Boolean) && (
            <div className="mt-4 flex items-center space-x-2">
              <span className="text-sm text-gray-500">Active filters:</span>
              {Object.entries(filters).map(([key, value]) =>
                value ? (
                  <span
                    key={key}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-blue-800"
                  >
                    {key}: {value}
                  </span>
                ) : null
              )}
              <button
                onClick={resetFilters}
                className="text-sm text-red-600 hover:text-red-500"
              >
                Clear all
              </button>
            </div>
          )}
        </div>

        {/* Bookings Table */}
        {status === "loading" ? (
          <LoadingSpinner />
        ) : status === "error" ? (
          <div className="text-center py-12">
            <div className="text-red-500 text-lg">{error.message}</div>
            <button
              onClick={() => fetchBookings(currentPage)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
            >
              Try again
            </button>
          </div>
        ) : (
          <div className="bg-white shadow rounded-lg">
            {/* Add this wrapper div for mobile scrolling */}
            <div className="max-w-full overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {[
                        { label: "Package Name", field: "bookingPackageName" },
                        { label: "Client Name", field: "clientName" },
                        { label: "Phone", field: "clientPhoneNo" },
                        { label: "Amount", field: "amountPaid" },
                        { label: "Guests", field: "totalGuest" },
                        { label: "Payment Status", field: "paymentStatus" },
                        { label: "Status", field: "status" },
                        { label: "Booked At", field: "bookedAt" },
                        { label: "Booking Date", field: "bookingDate" },
                        { label: "Actions", field: null }
                      ].map(({ label, field }) => (
                        <th
                          key={label}
                          onClick={() => field && handleSort(field)}
                          className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${field ? "cursor-pointer hover:bg-gray-100" : ""
                            }`}
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                            {field && <FaSort className="h-4 w-4" />}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {bookings.map((booking) => (
                      <tr key={booking._id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {booking.bookingPackageName}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {booking.clientName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {booking.clientEmail}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {booking.clientPhoneNo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            AED {booking.amountPaid.toLocaleString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {booking.totalGuest}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(booking.paymentStatus)
                            }`}>
                            {booking.paymentStatus}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(booking.status)
                            }`}>
                            {booking.status}
                          </span>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(booking.bookedAt), 'dd/MM/yyyy HH:mm')}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(booking.bookingDate), 'dd/MM/yyyy')}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center space-x-3">
                            <button
                              onClick={() => {
                                setSelectedBooking(booking);
                                setShowDetailsModal(true);
                              }}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <FaEye className="h-5 w-5" />
                            </button>

                            <button
                              onClick={() => {
                                setUpdatingBookingId(booking._id);
                                setShowStatusUpdate(true);
                              }}
                              className="text-yellow-600 hover:text-yellow-900"
                            >
                              <FaEdit className="h-5 w-5" />
                            </button>

                            <a
                              href={`https://wa.me/${booking.clientPhoneNo}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-green-600 hover:text-green-900"
                            >
                              <FaWhatsapp className="h-5 w-5" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination */}
            <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                  disabled={currentPage === totalPages}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing page <span className="font-medium">{currentPage}</span> of{' '}
                    <span className="font-medium">{totalPages}</span>
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    <button
                      onClick={() => setCurrentPage(1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      First
                    </button>
                    <button
                      onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                      disabled={currentPage === totalPages}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      Next
                    </button>
                    <button
                      onClick={() => setCurrentPage(totalPages)}
                      disabled={currentPage === totalPages}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      Last
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>


        )}

        {/* Booking Details Modal */}
        {showDetailsModal && selectedBooking && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Booking Details
                    </h3>
                    <div className="mt-4">
                      <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                        {/* Package Details */}
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Package</h4>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedBooking.bookingPackageName}
                          </p>
                        </div>

                        {/* Client Details */}
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Client Details</h4>
                          <div className="mt-1 space-y-1">
                            <p className="text-sm text-gray-900">
                              {selectedBooking.clientName}
                            </p>
                            <p className="text-sm text-gray-900">
                              {selectedBooking.clientEmail}
                            </p>
                            <p className="text-sm text-gray-900">
                              {selectedBooking.clientCountryCode} {selectedBooking.clientPhoneNo}
                            </p>
                          </div>
                        </div>

                        {/* Booking Details */}
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Amount Paid</h4>
                            <p className="mt-1 text-sm text-gray-900">
                              AED {selectedBooking.amountPaid.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Total Guests</h4>
                            <p className="mt-1 text-sm text-gray-900">
                              {selectedBooking.totalGuest}
                            </p>
                          </div>
                        </div>

                        {/* Status Information */}
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Payment Status</h4>
                            <span className={`mt-1 inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-full ${getStatusColor(selectedBooking.paymentStatus)
                              }`}>
                              {selectedBooking.paymentStatus}
                            </span>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Booking Status</h4>
                            <span className={`mt-1 inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-full ${getStatusColor(selectedBooking.status)
                              }`}>
                              {selectedBooking.status}
                            </span>
                          </div>
                        </div>


                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Payment ID</h4>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedBooking.paymentId || 'N/A'}
                          </p>
                        </div>

                        {/* Dates */}
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Booking Date</h4>
                            <p className="mt-1 text-sm text-gray-900">
                              {format(new Date(selectedBooking.bookingDate), 'dd/MM/yyyy')}
                            </p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">Booked At</h4>
                            <p className="mt-1 text-sm text-gray-900">
                              {format(new Date(selectedBooking.bookedAt), 'dd/MM/yyyy HH:mm')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between">

                  <a href={`https://wa.me/${selectedBooking.clientPhoneNo}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    <FaWhatsapp className="mr-2 h-5 w-5" />
                    Contact on WhatsApp
                  </a>
                  <button
                    type="button"
                    onClick={() => setShowDetailsModal(false)}
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Status Update Modal */}
        {showStatusUpdate && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Update Booking Status
                    </h3>
                    <div className="mt-4">
                      <div className="space-y-4">
                        {["Pending", "Complete", "Cancel"].map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusUpdate(updatingBookingId, status)}
                            disabled={isUpdatingStatus}
                            className={`w-full inline-flex justify-center rounded-md border px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${getStatusColor(status)
                              } ${isUpdatingStatus ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'}`}
                          >
                            {isUpdatingStatus ? (
                              <span className="inline-flex items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Updating...
                              </span>
                            ) : status}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => {
                      setShowStatusUpdate(false);
                      setUpdatingBookingId(null);
                    }}
                    disabled={isUpdatingStatus}
                    className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm ${isUpdatingStatus ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

