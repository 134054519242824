// pages/AddPackage.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  FaSave,
  FaTimes,
  FaImage,
  FaClock,
  FaGift,
  FaPlus,
  FaTrash,
  FaArrowLeft
} from "react-icons/fa";
import axios from "axios";
import API from "../connection/connections";
import { LoadingSpinner } from "../components/LoadingSpinner";
import MyHTMLEditor from "../components/MyHTMLEditor";
import toast from "react-hot-toast";

const ImagePreview = ({ image, onRemove, index }) => (
  <div className="relative group">
    <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-gray-100">
      <img
        src={URL.createObjectURL(image)}
        alt={`Preview ${index + 1}`}
        className="object-cover w-full h-full"
      />
      <button
        onClick={onRemove}
        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <FaTrash className="h-6 w-6 text-white" />
      </button>
    </div>
  </div>
);

const FormSection = ({ title, children, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden">
    <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        <Icon className="h-5 w-5 mr-2 text-gray-500" />
        {title}
      </h3>
    </div>
    <div className="p-4">{children}</div>
  </div>
);

export default function AddPackage() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editorHtml, setEditorHtml] = useState("");
  const [packageData, setPackageData] = useState({
    categoryId: "",
    packageName: "",
    price: "",
    specialPrice: "",
    packageUSP: "",
    enabled: true,
    bookingOnlyByWhatsapp: false,
    timeSlots: [{ from: "", to: "" }],
    perks: [{ icon: "", text: "" }],
    images: []
  });

  const [showHelpModal, setShowHelpModal] = useState(false);

  const [errors, setErrors] = useState({});

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API}/categories/view`);
        setCategories(response.data);
      } catch (error) {
        toast.error("Failed to load categories");
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPackageData(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => {
      if (file.size > 20097152) { // 20MB
        toast.error(`${file.name} is too large. Max size is 20MB`);
        return false;
      }
      if (!["image/jpeg", "image/jpg", "image/webp"].includes(file.type)) {
        toast.error(`${file.name} has invalid format. Use JPG, JPEG, or WEBP`);
        return false;
      }
      return true;
    });

    setPackageData(prev => ({
      ...prev,
      images: [...prev.images, ...validFiles]
    }));
  };

  const handleRemoveImage = (index) => {
    setPackageData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const handleAddTimeSlot = () => {
    setPackageData(prev => ({
      ...prev,
      timeSlots: [...prev.timeSlots, { from: "", to: "" }]
    }));
  };

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...packageData.timeSlots];
    updatedTimeSlots[index][field] = value;
    setPackageData(prev => ({
      ...prev,
      timeSlots: updatedTimeSlots
    }));
  };

  const handleRemoveTimeSlot = (index) => {
    if (packageData.timeSlots.length > 1) {
      setPackageData(prev => ({
        ...prev,
        timeSlots: prev.timeSlots.filter((_, i) => i !== index)
      }));
    }
  };

  const handleAddPerk = () => {
    setPackageData(prev => ({
      ...prev,
      perks: [...prev.perks, { icon: "", text: "" }]
    }));
  };

  const handlePerkChange = (index, field, value) => {
    const updatedPerks = [...packageData.perks];
    updatedPerks[index][field] = value;
    setPackageData(prev => ({
      ...prev,
      perks: updatedPerks
    }));
  };

  const handleRemovePerk = (index) => {
    if (packageData.perks.length > 1) {
      setPackageData(prev => ({
        ...prev,
        perks: prev.perks.filter((_, i) => i !== index)
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!packageData.categoryId) newErrors.categoryId = "Category is required";
    if (!packageData.packageName) newErrors.packageName = "Package name is required";
    if (!packageData.price) newErrors.price = "Price is required";
    if (!packageData.specialPrice) newErrors.specialPrice = "Special price is required";
    if (!packageData.packageUSP) newErrors.packageUSP = "Package USP is required";
    if (!editorHtml) newErrors.description = "Description is required";
    if (packageData.images.length === 0) newErrors.images = "At least one image is required";
    
    if (packageData.timeSlots.some(slot => !slot.from || !slot.to)) {
      newErrors.timeSlots = "All time slots must be filled";
    }
    
    if (packageData.perks.some(perk => !perk.icon || !perk.text)) {
      newErrors.perks = "All perk details must be filled";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Continuing AddPackage.jsx...

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields");
      return;
    }

    setSubmitting(true);
    const formData = new FormData();

    // Append basic fields
    Object.keys(packageData).forEach(key => {
      if (!['images', 'timeSlots', 'perks'].includes(key)) {
        formData.append(key, packageData[key]);
      }
    });

    // Append description
    formData.append("description", editorHtml);

    // Append arrays as JSON strings
    formData.append("timeSlots", JSON.stringify(packageData.timeSlots));
    formData.append("perks", JSON.stringify(packageData.perks));

    // Append images
    packageData.images.forEach(image => {
      formData.append("images", image);
    });

    try {
      await axios.post(`${API}/packages/add`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data"
        }
      });
      
      toast.success("Package added successfully");
      navigate("/packages");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add package");
      console.error("Error adding package:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/packages"
                className="mr-4 flex items-center text-gray-600 hover:text-gray-900"
              >
                <FaArrowLeft className="h-5 w-5" />
              </Link>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Add New Package</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Create a new travel package
                </p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Basic Information */}
          <FormSection title="Basic Information" icon={FaGift}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category *
                </label>
                <select
                  name="categoryId"
                  value={packageData.categoryId}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    errors.categoryId ? 'border-red-300' : 'border-gray-300'
                  }`}
                >
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category._id} value={category._id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
                {errors.categoryId && (
                  <p className="mt-1 text-sm text-red-600">{errors.categoryId}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Package Name *
                </label>
                <input
                  type="text"
                  name="packageName"
                  value={packageData.packageName}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    errors.packageName ? 'border-red-300' : 'border-gray-300'
                  }`}
                />
                {errors.packageName && (
                  <p className="mt-1 text-sm text-red-600">{errors.packageName}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Regular Price *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">AED </span>
                  </div>
                  <input
                    type="number"
                    name="price"
                    value={packageData.price}
                    onChange={handleChange}
                    className={`block w-full pl-7 pr-12 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.price ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                </div>
                {errors.price && (
                  <p className="mt-1 text-sm text-red-600">{errors.price}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Special Price *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">AED </span>
                  </div>
                  <input
                    type="number"
                    name="specialPrice"
                    value={packageData.specialPrice}
                    onChange={handleChange}
                    className={`block w-full pl-7 pr-12 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.specialPrice ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                </div>
                {errors.specialPrice && (
                  <p className="mt-1 text-sm text-red-600">{errors.specialPrice}</p>
                )}
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Package USP *
                </label>
                <textarea
                  name="packageUSP"
                  value={packageData.packageUSP}
                  onChange={handleChange}
                  rows={3}
                  className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    errors.packageUSP ? 'border-red-300' : 'border-gray-300'
                  }`}
                />
                {errors.packageUSP && (
                  <p className="mt-1 text-sm text-red-600">{errors.packageUSP}</p>
                )}
              </div>
            </div>
          </FormSection>

          {/* Images Section */}
          <FormSection title="Package Images" icon={FaImage}>
            <div className="space-y-4">
              <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                <div className="space-y-1 text-center">
                  <FaImage className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>Upload images</span>
                      <input
                        type="file"
                        multiple
                        accept=".jpg,.jpeg,.webp"
                        onChange={handleImageChange}
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    JPG, JPEG, WEBP up to 20MB
                  </p>
                </div>
              </div>

              {errors.images && (
                <p className="text-sm text-red-600">{errors.images}</p>
              )}

              {packageData.images.length > 0 && (
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                  {packageData.images.map((image, index) => (
                    <ImagePreview
                      key={index}
                      image={image}
                      index={index}
                      onRemove={() => handleRemoveImage(index)}
                    />
                  ))}
                </div>
              )}
            </div>
          </FormSection>

          {/* Description Section */}
          <FormSection title="Package Description" icon={FaGift}>
            <MyHTMLEditor
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description}</p>
            )}
          </FormSection>

          {/* Time Slots Section */}
          <FormSection title="Time Slots" icon={FaClock}>
            <div className="space-y-4">
              {packageData.timeSlots.map((slot, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <div className="flex-1 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">From</label>
                      <input
                        type="time"
                        value={slot.from}
                        onChange={(e) => handleTimeSlotChange(index, 'from', e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">To</label>
                      <input
                        type="time"
                        value={slot.to}
                        onChange={(e) => handleTimeSlotChange(index, 'to', e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveTimeSlot(index)}
                      className="p-2 text-red-600 hover:text-red-700"
                    >
                      <FaTrash className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}

              <button
                type="button"
                onClick={handleAddTimeSlot}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-purple-100 hover:bg-purple-200"
              >
                <FaPlus className="mr-2 h-5 w-5" />
                Add Time Slot
              </button>

              {errors.timeSlots && (
                <p className="mt-1 text-sm text-red-600">{errors.timeSlots}</p>
              )}
            </div>
          </FormSection>

          {/* Perks Section */}
          <FormSection title="Package Perks" icon={FaGift}>
            <div className="space-y-4">
              {packageData.perks.map((perk, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <div className="flex-1 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Icon Class</label>
                      <input
                        type="text"
                        value={perk.icon}
                        onChange={(e) => handlePerkChange(index, 'icon', e.target.value)}
                        placeholder="e.g., fas fa-star"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Description</label>
                      <input
                        type="text"
                        value={perk.text}
                        onChange={(e) => handlePerkChange(index, 'text', e.target.value)}
                        placeholder="Perk description"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemovePerk(index)}
                      className="p-2 text-red-600 hover:text-red-700"
                    >
                      <FaTrash className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}

              <button
                type="button"
                onClick={handleAddPerk}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-purple-100 hover:bg-purple-200"
              >
                <FaPlus className="mr-2 h-5 w-5" />
                Add Perk
              </button>

              {errors.perks && (
                <p className="mt-1 text-sm text-red-600">{errors.perks}</p>
              )}
            </div>
          </FormSection>

          {/* Package Settings */}
          <FormSection title="Package Settings" icon={FaGift}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Package Status
                </label>
                <select
                  name="enabled"
                  value={packageData.enabled}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value={true}>Enabled</option>
                  <option value={false}>Disabled</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  WhatsApp Booking Only
                </label>
                <select
                  // Continuing AddPackage.jsx...

                  name="bookingOnlyByWhatsapp"
                  value={packageData.bookingOnlyByWhatsapp}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>
          </FormSection>

          {/* Form Actions */}
          <div className="flex justify-end space-x-3 pt-6">
            <Link
              to="/packages"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <FaTimes className="mr-2 h-5 w-5" />
              Cancel
            </Link>
            <button
              type="submit"
              disabled={submitting}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {submitting ? (
                <>
                  <LoadingSpinner small />
                  <span className="ml-2">Creating Package...</span>
                </>
              ) : (
                <>
                  <FaSave className="mr-2 h-5 w-5" />
                  Create Package
                </>
              )}
            </button>
          </div>
        </form>

        {/* Help Modal */}
        <div className="fixed bottom-4 right-4">
          <button
            onClick={() => setShowHelpModal(true)}
            className="bg-purple-600 text-white rounded-full p-4 shadow-lg hover:bg-purple-700 transition-colors"
            title="Need help?"
          >
            <FaGift className="h-6 w-6" />
          </button>
        </div>

        {/* Help Modal Content */}
        {showHelpModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Package Creation Help</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-gray-700">Images</h4>
                  <p className="text-sm text-gray-600">
                    Upload high-quality images in JPG, JPEG, or WEBP format. Maximum size: 20MB per image.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-700">Time Slots</h4>
                  <p className="text-sm text-gray-600">
                    Add multiple time slots for package availability. Each slot must have both start and end times.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-700">Perks</h4>
                  <p className="text-sm text-gray-600">
                    Use Font Awesome icon classes (e.g., 'fas fa-star'). Each perk needs both an icon and description.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-700">WhatsApp Booking</h4>
                  <p className="text-sm text-gray-600">
                    Enable this if bookings should only be processed through WhatsApp.
                  </p>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowHelpModal(false)}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

