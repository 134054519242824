// components/Header.jsx
import React from "react";
import { useAuth } from "../context/AuthContext";
import { Menu, Bell, User } from "lucide-react";

const Header = ({ setSidebarOpen }) => {
  const { user, logout } = useAuth();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const dropdownRef = React.useRef(null);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <header className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
      <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        {/* Left section */}
        <div className="flex items-center">
          <button
            onClick={() => setSidebarOpen(true)}
            className="lg:hidden -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          >
            <span className="sr-only">Open sidebar</span>
            <Menu size={24} />
          </button>
          <div className="flex items-center ml-4 lg:ml-0">
            <img 
              src="https://tripmenia.com/assets/logo-DZGcI0Uq.png" 
              alt="Tripmenia Logo" 
              className="h-8 w-auto"
            />
          </div>
        </div>

        {/* Right section */}
        <div className="flex items-center space-x-4">
          <button className="flex-shrink-0 p-2 text-gray-500 hover:text-gray-900">
            <Bell size={20} />
          </button>

          <div className="relative flex-shrink-0" ref={dropdownRef}>
            {/* ... rest of the header content ... */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;