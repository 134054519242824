// pages/BannerManager.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import API from "../connection/connections";
import { FaUpload, FaImage, FaCheck } from "react-icons/fa";
import { LoadingSpinner } from "../components/LoadingSpinner";
import toast from "react-hot-toast";

const BANNER_TYPES = [
  { id: 'topBanner', name: 'Top Banner', endpoint: 'update-top-banner' },
  { id: 'middleBanner', name: 'Middle Banner', endpoint: 'update-middle-banner' },
  { id: 'footerBanner', name: 'Footer Banner', endpoint: 'update-footer-banner' }
];

const ImagePreview = ({ src, alt }) => (
  <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-gray-100">
    <img
      src={src}
      alt={alt}
      className="object-cover w-full h-full transform transition-transform duration-300 hover:scale-105"
    />
  </div>
);

const BannerUploadSection = ({ 
  title, 
  currentImage, 
  onFileSelect, 
  onSubmit, 
  isUploading,
  error 
}) => (
  <div className="bg-white rounded-lg shadow overflow-hidden">
    <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        <FaImage className="h-5 w-5 mr-2 text-gray-500" />
        {title}
      </h3>
    </div>
    <div className="p-4 space-y-4">
      {/* Current Banner */}
      {currentImage && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Current Banner
          </label>
          <ImagePreview 
            src={`https://tripmenia.com/${currentImage}`} 
            alt={title} 
          />
        </div>
      )}

      {/* Upload Form */}
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Upload New Banner
          </label>
          <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors duration-300">
            <div className="space-y-1 text-center">
              <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                  <span>Upload a file</span>
                  <input
                    type="file"
                    className="sr-only"
                    onChange={onFileSelect}
                    accept="image/*"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
          </div>
        </div>

        {error && (
          <div className="text-sm text-red-600">
            {error}
          </div>
        )}

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isUploading}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isUploading ? (
              <>
                <LoadingSpinner small />
                <span className="ml-2">Uploading...</span>
              </>
            ) : (
              <>
                <FaCheck className="mr-2 h-4 w-4" />
                Update Banner
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
);

export default function BannerManager() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [banners, setBanners] = useState({
    topBanner: "",
    middleBanner: "",
    footerBanner: ""
  });
  const [selectedFiles, setSelectedFiles] = useState({
    topBanner: null,
    middleBanner: null,
    footerBanner: null
  });
  const [uploading, setUploading] = useState({
    topBanner: false,
    middleBanner: false,
    footerBanner: false
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    fetchBanners();
  }, [navigate, token]);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`https://tripmenia.com/api/banners/get-banner`);
      const fetchedBanners = response.data.banner.reduce((acc, banner) => {
        acc[banner.name] = banner.imagePath;
        return acc;
      }, {});
      setBanners(fetchedBanners);
    } catch (error) {
      toast.error("Failed to load banners");
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (bannerId, e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10485760) { // 10MB
        setErrors(prev => ({
          ...prev,
          [bannerId]: "File size must be less than 10MB"
        }));
        return;
      }
      if (!file.type.startsWith('image/')) {
        setErrors(prev => ({
          ...prev,
          [bannerId]: "Please upload an image file"
        }));
        return;
      }
      setSelectedFiles(prev => ({ ...prev, [bannerId]: file }));
      setErrors(prev => ({ ...prev, [bannerId]: null }));
    }
  };

  const handleSubmit = async (bannerId, endpoint, e) => {
    e.preventDefault();
    if (!selectedFiles[bannerId]) return;

    const formData = new FormData();
    formData.append("file", selectedFiles[bannerId]);

    setUploading(prev => ({ ...prev, [bannerId]: true }));
    try {
      await axios.post(`https://tripmenia.com/api/banners/${endpoint}`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data"
        }
      });
      await fetchBanners();
      toast.success(`${bannerId} updated successfully`);
      setSelectedFiles(prev => ({ ...prev, [bannerId]: null }));
    } catch (error) {
      toast.error(`Failed to update ${bannerId}`);
      setErrors(prev => ({
        ...prev,
        [bannerId]: error.response?.data?.message || "Failed to update banner"
      }));
    } finally {
      setUploading(prev => ({ ...prev, [bannerId]: false }));
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Banner Manager</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your website banners and promotional images
          </p>
        </div>

        {/* Banner Sections */}
        <div className="space-y-6">
          {BANNER_TYPES.map(({ id, name, endpoint }) => (
            <BannerUploadSection
              key={id}
              title={name}
              currentImage={banners[id]}
              onFileSelect={(e) => handleFileSelect(id, e)}
              onSubmit={(e) => handleSubmit(id, endpoint, e)}
              isUploading={uploading[id]}
              error={errors[id]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}