// services/api.js
import axios from 'axios';

//const API_BASE_URL = 'https://tripmenia.com/api';
 const API_BASE_URL = 'https://tripmenia.com/api';

// Create axios instance with default config
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add token to requests if it exists
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Handle response errors globally
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export const authAPI = {
    login: async (credentials) => {
        try {
            const response = await api.post('/admin/login', credentials);
            return response.data;
        } catch (error) {
            throw error.response?.data || error;
        }
    },

    getUser: async () => {
        try {
            const response = await api.get('/admin/me');
            return response.data;
        } catch (error) {
            throw error.response?.data || error;
        }
    },
};

export default api;