// pages/EditPackage.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  FaSave,
  FaTimes,
  FaImage,
  FaClock,
  FaGift,
  FaPlus,
  FaTrash,
  FaArrowLeft,
  FaCheck,
} from "react-icons/fa";
import axios from "axios";
import API from "../connection/connections";
import { LoadingSpinner } from "../components/LoadingSpinner";
import MyHTMLEditor from "../components/MyHTMLEditor";
import toast from "react-hot-toast";

const ImagePreview = ({ image, onRemove, existing = false }) => (
  <div className="relative group">
    <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-gray-100">
      <img
        src={
          existing
            ? `https://tripmenia.com/public/upload/${image}`
            : URL.createObjectURL(image)
        }
        alt="Preview"
        className="object-cover w-full h-full"
      />
      <button
        onClick={onRemove}
        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <FaTrash className="h-6 w-6 text-white" />
      </button>
    </div>
  </div>
);

const FormSection = ({ title, children, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden">
    <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        <Icon className="h-5 w-5 mr-2 text-gray-500" />
        {title}
      </h3>
    </div>
    <div className="p-4">{children}</div>
  </div>
);

export default function EditPackage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editorHtml, setEditorHtml] = useState("");
  const [packageData, setPackageData] = useState({
    categoryId: "",
    packageName: "",
    price: "",
    specialPrice: "",
    packageUSP: "",
    enabled: true,
    bookingOnlyByWhatsapp: false,
    timeSlots: [{ from: "", to: "" }],
    perks: [{ icon: "", text: "" }],
    existingImages: [],
    newImages: [],
  });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch package and categories data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [packageRes, categoriesRes] = await Promise.all([
          axios.get(`${API}/packages/viewpackagedetail/${id}`),
          axios.get(`${API}/categories/view`),
        ]);

        const pkgData = packageRes.data;
        setPackageData({
          categoryId: pkgData.categoryId._id,
          packageName: pkgData.packageName,
          price: pkgData.price,
          specialPrice: pkgData.specialPrice,
          packageUSP: pkgData.packageUSP,
          enabled: pkgData.enabled,
          bookingOnlyByWhatsapp: pkgData.bookingOnlyByWhatsapp || false,
          timeSlots: pkgData.timeSlots,
          perks: pkgData.perks || [],
          existingImages: pkgData.images || [],
          newImages: [],
        });
        setEditorHtml(pkgData.description);
        setCategories(categoriesRes.data);
      } catch (error) {
        toast.error("Failed to load package details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPackageData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      if (file.size > 20097152) {
        toast.error("File size should not exceed 20MB");
        return false;
      }
      if (!["image/jpeg", "image/jpg", "image/webp"].includes(file.type)) {
        toast.error("Only JPG, JPEG, and WEBP files are allowed");
        return false;
      }
      return true;
    });

    setPackageData((prev) => ({
      ...prev,
      newImages: [...prev.newImages, ...validFiles],
    }));
  };

  const handleRemoveImage = (index, type) => {
    setPackageData((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));
  };

  const handleAddTimeSlot = () => {
    setPackageData((prev) => ({
      ...prev,
      timeSlots: [...prev.timeSlots, { from: "", to: "" }],
    }));
  };

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...packageData.timeSlots];
    updatedTimeSlots[index][field] = value;
    setPackageData((prev) => ({
      ...prev,
      timeSlots: updatedTimeSlots,
    }));
  };

  const handleRemoveTimeSlot = (index) => {
    setPackageData((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter((_, i) => i !== index),
    }));
  };

  const handleAddPerk = () => {
    setPackageData((prev) => ({
      ...prev,
      perks: [...prev.perks, { icon: "", text: "" }],
    }));
  };

  const handlePerkChange = (index, field, value) => {
    const updatedPerks = [...packageData.perks];
    updatedPerks[index][field] = value;
    setPackageData((prev) => ({
      ...prev,
      perks: updatedPerks,
    }));
  };

  const handleRemovePerk = (index) => {
    setPackageData((prev) => ({
      ...prev,
      perks: prev.perks.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!packageData.categoryId) newErrors.categoryId = "Category is required";
    if (!packageData.packageName)
      newErrors.packageName = "Package name is required";
    if (!packageData.price) newErrors.price = "Price is required";
    if (!packageData.specialPrice)
      newErrors.specialPrice = "Special price is required";
    if (!packageData.packageUSP)
      newErrors.packageUSP = "Package USP is required";
    if (!editorHtml) newErrors.description = "Description is required";

    if (packageData.timeSlots.some((slot) => !slot.from || !slot.to)) {
      newErrors.timeSlots = "All time slots must be filled";
    }

    if (packageData.perks.some((perk) => !perk.icon || !perk.text)) {
      newErrors.perks = "All perk details must be filled";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields");
      return;
    }

    setSubmitting(true);
    const formData = new FormData();

    // Append basic fields
    Object.keys(packageData).forEach((key) => {
      if (
        !["existingImages", "newImages", "timeSlots", "perks"].includes(key)
      ) {
        formData.append(key, packageData[key]);
      }
    });

    // Append description
    formData.append("description", editorHtml);

    // Append time slots and perks
    formData.append("timeSlots", JSON.stringify(packageData.timeSlots));
    formData.append("perks", JSON.stringify(packageData.perks));

    // Append images
    packageData.newImages.forEach((image) => {
      formData.append("images", image);
    });
    formData.append(
      "existingImages",
      JSON.stringify(packageData.existingImages)
    );

    try {
      await axios.patch(`${API}/packages/${id}`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Package updated successfully");
      navigate(`/packages/details/${id}`);
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update package");
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to={`/packages/details/${id}`}
                className="mr-4 flex items-center text-gray-600 hover:text-gray-900"
              >
                <FaArrowLeft className="h-5 w-5" />
              </Link>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Edit Package
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  Update the details for {packageData.packageName}
                </p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Basic Information */}
          <FormSection title="Basic Information" icon={FaGift}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category *
                </label>
                <select
                  name="categoryId"
                  value={packageData.categoryId}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    errors.categoryId ? "border-red-300" : ""
                  }`}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
                {errors.categoryId && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.categoryId}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Package Name *
                </label>
                <input
                  type="text"
                  name="packageName"
                  value={packageData.packageName}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    errors.packageName ? "border-red-300" : ""
                  }`}
                />
                {errors.packageName && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.packageName}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Regular Price *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">AED </span>
                  </div>
                  <input
                    type="number"
                    name="price"
                    value={packageData.price}
                    onChange={handleChange}
                    className={`block w-full pl-7 pr-12 rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                      errors.price ? "border-red-300" : ""
                    }`}
                  />
                </div>
                {errors.price && (
                  <p className="mt-1 text-sm text-red-600">{errors.price}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Special Price *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">AED </span>
                  </div>
                  <input
                    type="number"
                    name="specialPrice"
                    value={packageData.specialPrice}
                    onChange={handleChange}
                    className={`block w-full pl-7 pr-12 rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                      errors.specialPrice ? "border-red-300" : ""
                    }`}
                  />
                </div>
                {errors.specialPrice && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.specialPrice}
                  </p>
                )}
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Package USP *
                </label>
                <textarea
                  name="packageUSP"
                  value={packageData.packageUSP}
                  onChange={handleChange}
                  rows={3}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    errors.packageUSP ? "border-red-300" : ""
                  }`}
                />
                {errors.packageUSP && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.packageUSP}
                  </p>
                )}
              </div>
            </div>
          </FormSection>

          {/* Images Section */}
          <FormSection title="Package Images" icon={FaImage}>
            <div className="space-y-4">
              <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                <div className="space-y-1 text-center">
                  <FaImage className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                      <span>Upload images</span>
                      <input
                        type="file"
                        multiple
                        accept=".jpg,.jpeg,.webp"
                        onChange={handleImageChange}
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    JPG, JPEG, WEBP up to 20MB
                  </p>
                </div>
              </div>

              {/* Image Previews */}
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                {packageData.existingImages.map((image, index) => (
                  <ImagePreview
                    key={`existing-${index}`}
                    image={image}
                    onRemove={() => handleRemoveImage(index, "existingImages")}
                    existing={true}
                  />
                ))}
                {packageData.newImages.map((image, index) => (
                  <ImagePreview
                    key={`new-${index}`}
                    image={image}
                    onRemove={() => handleRemoveImage(index, "newImages")}
                  />
                ))}
              </div>
            </div>
          </FormSection>

          {/* Description Section */}
          <FormSection title="Package Description" icon={FaGift}>
            <MyHTMLEditor
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description}</p>
            )}
          </FormSection>

          {/* Time Slots Section */}
          <FormSection title="Time Slots" icon={FaClock}>
            <div className="space-y-4">
              {packageData.timeSlots.map((slot, index) => (
                <div key={index} className="flex items-center gap-4">
                  <input
                    type="time"
                    value={slot.from}
                    onChange={(e) =>
                      handleTimeSlotChange(index, "from", e.target.value)
                    }
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  />
                  <input
                    type="time"
                    value={slot.to}
                    onChange={(e) =>
                      handleTimeSlotChange(index, "to", e.target.value)
                    }
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  />
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveTimeSlot(index)}
                      className="text-red-600 hover:text-red-700"
                    >
                      <FaTrash className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddTimeSlot}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-purple-100 hover:bg-purple-200"
              >
                <FaPlus className="mr-2 h-5 w-5" />
                Add Time Slot
              </button>
              {errors.timeSlots && (
                <p className="mt-1 text-sm text-red-600">{errors.timeSlots}</p>
              )}
            </div>
          </FormSection>

          <FormSection title="Package Perks" icon={FaGift}>
            <div className="space-y-4">
              {packageData.perks.map((perk, index) => (
                <div key={index} className="flex items-start space-x-4">
                  <div className="flex-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Icon Class
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          value={perk.icon}
                          onChange={(e) =>
                            handlePerkChange(index, "icon", e.target.value)
                          }
                          placeholder="e.g., fas fa-star"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        />
                        {perk.icon && (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                            <i className={`${perk.icon} text-gray-400`}></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Perk Description
                      </label>
                      <input
                        type="text"
                        value={perk.text}
                        onChange={(e) =>
                          handlePerkChange(index, "text", e.target.value)
                        }
                        placeholder="Perk description"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemovePerk(index)}
                      className="mt-6 p-2 text-red-600 hover:text-red-700 focus:outline-none"
                    >
                      <FaTrash className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}

              <div className="flex items-center justify-between pt-4">
                <button
                  type="button"
                  onClick={handleAddPerk}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaPlus className="mr-2 h-5 w-5" />
                  Add Perk
                </button>

                {/* Icon Reference Link */}

                <a
                  href="https://fontawesome.com/icons"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  View Available Icons
                </a>
              </div>

              {/* Preview Section */}
              {packageData.perks.length > 0 && (
                <div className="mt-6">
                  <h4 className="text-sm font-medium text-gray-700 mb-3">
                    Perks Preview
                  </h4>
                  <div className="bg-gray-50 rounded-lg p-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {packageData.perks.map((perk, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-3 p-3 bg-white rounded-lg shadow-sm"
                      >
                        {perk.icon && (
                          <i
                            className={`${perk.icon} text-blue-500 text-lg`}
                          ></i>
                        )}
                        <span className="text-sm text-gray-700">
                          {perk.text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {errors.perks && (
                <p className="mt-1 text-sm text-red-600">{errors.perks}</p>
              )}
            </div>
          </FormSection>

          {/* Package Settings */}
          <FormSection title="Package Settings" icon={FaGift}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Package Status
                </label>
                <select
                  name="enabled"
                  value={packageData.enabled}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value={true}>Enabled</option>
                  <option value={false}>Disabled</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  WhatsApp Booking Only
                </label>
                <select
                  name="bookingOnlyByWhatsapp"
                  value={packageData.bookingOnlyByWhatsapp}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>
          </FormSection>

          {/* Form Actions */}
          <div className="flex justify-end space-x-3">
            <Link
              to={`/packages/details/${id}`}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <FaTimes className="mr-2 h-5 w-5" />
              Cancel
            </Link>
            <button
              type="submit"
              disabled={submitting}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {submitting ? (
                <>
                  <LoadingSpinner small />

                  <span className="ml-2">Updating...</span>
                </>
              ) : (
                <>
                  <FaSave className="mr-2 h-5 w-5" />
                  Update Package
                </>
              )}
            </button>
          </div>
        </form>

        {/* Discard Changes Modal */}
        {showDiscardModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <FaTimes className="h-6 w-6 text-yellow-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Discard Changes?
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to discard your changes? All
                        unsaved changes will be lost.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    onClick={() => navigate(`/viewpackagedetails/${id}`)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  >
                    Discard Changes
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowDiscardModal(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  >
                    Continue Editing
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Success Modal */}

        {/* Error Modal */}
        {showErrorModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <FaTimes className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Error Updating Package
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        An error occurred while updating the package. Please try
                        again.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={() => setShowErrorModal(false)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                  >
                    Try Again
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
