// pages/EditCategory.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { FaSave, FaTimes, FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import API from "../connection/connections";
import { LoadingSpinner } from "../components/LoadingSpinner";
import toast from "react-hot-toast";

export default function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [categoryData, setCategoryData] = useState({
    categoryName: "",
    categoryDescription: "",
  });
  const [errors, setErrors] = useState({});
  const [initialData, setInitialData] = useState(null);

  // Fetch category data
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${API}/categories/view/${id}`);
        const data = response.data;
        setCategoryData({
          categoryName: data.categoryName,
          categoryDescription: data.categoryDescription,
        });
        setInitialData({
          categoryName: data.categoryName,
          categoryDescription: data.categoryDescription,
        });
      } catch (error) {
        toast.error("Failed to load category");
        console.error("Error fetching category:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!categoryData.categoryName.trim()) {
      newErrors.categoryName = "Category name is required";
    }
    if (!categoryData.categoryDescription.trim()) {
      newErrors.categoryDescription = "Category description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const hasChanges = () => {
    return JSON.stringify(categoryData) !== JSON.stringify(initialData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields");
      return;
    }

    if (!hasChanges()) {
      toast.info("No changes to save");
      return;
    }

    setSubmitting(true);
    try {
      await axios.patch(`${API}/categories/${id}`, categoryData, {
        headers: {
          Authorization: token,
        },
      });
      
      toast.success("Category updated successfully");
      navigate("/categories");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update category");
      console.error("Error updating category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header */}
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Link
                  to="/categories"
                  className="mr-4 text-gray-600 hover:text-gray-900"
                >
                  <FaArrowLeft className="h-5 w-5" />
                </Link>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    Edit Category
                  </h1>
                  <p className="mt-1 text-sm text-gray-500">
                    Update category information
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Category Name */}
            <div>
              <label 
                htmlFor="categoryName" 
                className="block text-sm font-medium text-gray-700"
              >
                Category Name *
              </label>
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                value={categoryData.categoryName}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm
                  ${errors.categoryName ? 'border-red-300' : 'border-gray-300'}`}
              />
              {errors.categoryName && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.categoryName}
                </p>
              )}
            </div>

            {/* Category Description */}
            <div>
              <label 
                htmlFor="categoryDescription" 
                className="block text-sm font-medium text-gray-700"
              >
                Category Description *
              </label>
              <textarea
                id="categoryDescription"
                name="categoryDescription"
                rows={4}
                value={categoryData.categoryDescription}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm
                  ${errors.categoryDescription ? 'border-red-300' : 'border-gray-300'}`}
              />
              {errors.categoryDescription && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.categoryDescription}
                </p>
              )}
            </div>

            {/* Form Actions */}
            <div className="flex justify-end space-x-3 pt-4">
              <Link
                to="/categories"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <FaTimes className="mr-2 h-5 w-5" />
                Cancel
              </Link>
              <button
                type="submit"
                disabled={submitting || !hasChanges()}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {submitting ? (
                  <>
                    <LoadingSpinner small />
                    <span className="ml-2">Saving...</span>
                  </>
                ) : (
                  <>
                    <FaSave className="mr-2 h-5 w-5" />
                    Save Changes
                  </>
                )}
              </button>
            </div>
          </form>

          {/* Unsaved Changes Warning */}
          {hasChanges() && (
            <div className="px-6 py-4 bg-yellow-50 border-t border-yellow-100">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FaTimes className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Unsaved Changes
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      You have unsaved changes. Make sure to save before leaving this page.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}