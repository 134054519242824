// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { FaUsers, FaShoppingCart, FaMoneyBillWave, FaPlane, FaCalendar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API from '../connection/connections';

export default function Dashboard() {
 const [dashboardData, setDashboardData] = useState({
   stats: {
     totalBookings: 0,
     activePackages: 0, 
     totalRevenue: 0,
     totalCustomers: 0
   },
   recentBookings: [],
   popularPackages: []
 });

 const [loading, setLoading] = useState(true);

 useEffect(() => {
   fetchDashboardData();
 }, []);

 const fetchDashboardData = async () => {
   try {
     const { data } = await axios.get(`${API}/admin/stats`);
     setDashboardData(data);
   } catch (error) {
     console.error('Error fetching dashboard data:', error);
   } finally {
     setLoading(false);
   }
 };

 if (loading) {
   return <div>Loading...</div>;
 }

 return (
   <div className="min-h-full bg-gray-50 py-6">
     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
       <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
         <StatsCard 
           title="Total Bookings"
           value={dashboardData.stats.totalBookings}
           icon={<FaShoppingCart />}
           color="blue"
         />
         <StatsCard 
           title="Active Packages"
           value={dashboardData.stats.activePackages}
           icon={<FaPlane />}
           color="green"
         />
         <StatsCard 
           title="Total Revenue"
           value={`AED ${dashboardData.stats.totalRevenue.toLocaleString()}`}
           icon={<FaMoneyBillWave />}
           color="purple"
         />
         <StatsCard 
           title="Total Customers" 
           value={dashboardData.stats.totalCustomers}
           icon={<FaUsers />}
           color="yellow"
         />
       </div>

       <div className="grid grid-cols-1 gap-5 mt-8 lg:grid-cols-2">
         <div className="bg-white rounded-lg shadow">
           <div className="p-6 border-b border-gray-200">
             <div className="flex items-center justify-between">
               <h2 className="text-lg font-medium">Recent Bookings</h2>
               <Link to="/bookings" className="text-blue-600 text-sm">View all</Link>
             </div>
           </div>
           <div className="p-6">
             <ul className="divide-y divide-gray-200">
               {dashboardData.recentBookings.map((booking) => (
                 <li key={booking._id} className="py-4">
                   <div className="flex items-center space-x-4">
                     <div className="flex-shrink-0">
                       <FaCalendar className="text-blue-500" />
                     </div>
                     <div className="flex-1">
                       <p className="font-medium">{booking.bookingPackageName}</p>
                       <p className="text-gray-500">{booking.clientName}</p>
                     </div>
                     <div>
                       <span className={`px-2 py-1 rounded-full text-xs ${
                         booking.status === 'Complete' 
                           ? 'bg-green-100 text-green-800' 
                           : 'bg-yellow-100 text-yellow-800'
                       }`}>
                         {booking.status}
                       </span>
                     </div>
                     <div className="text-gray-900">AED {booking.amountPaid}</div>
                   </div>
                 </li>
               ))}
             </ul>
           </div>
         </div>

         <div className="bg-white rounded-lg shadow">
           <div className="p-6 border-b border-gray-200">
             <div className="flex items-center justify-between">
               <h2 className="text-lg font-medium">Popular Packages</h2>
               <Link to="/packages" className="text-blue-600 text-sm">View all</Link>
             </div>
           </div>
           <div className="p-6">
             <ul className="divide-y divide-gray-200">
               {dashboardData.popularPackages.map((pkg) => (
                 <li key={pkg._id} className="py-4">
                   <div className="flex items-center space-x-4">
                     <div className="flex-shrink-0">
                       <FaPlane className="text-purple-500" />
                     </div>
                     <div className="flex-1">
                       <p className="font-medium">{pkg.packageName}</p>
                       <p className="text-gray-500">{pkg.bookingCount} bookings</p>
                     </div>
                   </div>
                 </li>
               ))}
             </ul>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
}

function StatsCard({ title, value, icon, color }) {
 const colors = {
   blue: "bg-purple-500",
   green: "bg-green-500", 
   purple: "bg-purple-500",
   yellow: "bg-yellow-500"
 };

 return (
   <div className="bg-white rounded-lg shadow p-5">
     <div className="flex items-center">
       <div className={`p-3 rounded-md ${colors[color]} bg-opacity-10`}>
         {React.cloneElement(icon, { className: `h-6 w-6 ${colors[color]}` })}
       </div>
       <div className="ml-5">
         <p className="text-gray-500 text-sm">{title}</p>
         <p className="text-2xl font-semibold">{value}</p>
       </div>
     </div>
   </div>
 );
}